"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailMessageEvent = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
/**
 * Define a general purpose Event Bridge message detail schema for triggering
 * emails.
 */
const EmailEventDetail = typebox_1.Type.Object({
    templateId: typebox_1.Type.String({
        title: 'Email template',
        description: 'Id of the email template to be sent',
    }),
    from: model_1.Recipient,
    to: model_1.Recipient,
    subject: typebox_1.Type.String({
        description: 'Subject line of email',
        maxLength: 300,
    }),
    triggerId: typebox_1.Type.String({
        description: 'Trigger ID of email event',
        format: 'uuid',
    }),
});
/**
 * Define a specialization of the `EmailEventDetail` for the "resend access
 * code" use case.
 */
const ResendAccessCodeEventDetail = typebox_1.Type.Intersect([
    EmailEventDetail,
    typebox_1.Type.Object({
        accessCode: typebox_1.Type.String({
            description: 'Access Code of guest',
        }),
        templateId: typebox_1.Type.String({
            description: 'Resend Access Code email template id',
            format: 'regex',
            pattern: '^d-[a-z0-9]{32}',
        }),
    }),
]);
exports.EmailMessageEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * An id representing a batch of emails to be sent at the same time.
         * Including a `batchId` in the message includes the `messages` in the
         * identified batch. A batch may be canceled or paused via email provider
         * tools.
         */
        batchId: typebox_1.Type.Optional(typebox_1.Type.String({ minLength: 32 })),
        messages: typebox_1.Type.Union([
            typebox_1.Type.Array(ResendAccessCodeEventDetail, { maxItems: 1 }),
        ]),
    }),
    'detail-type': typebox_1.Type.Literal('EmailMessage'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
